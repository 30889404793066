<template>
  <div class="d-flex justify-content-between align-items-baseline my-sm-5 mt-4 mb-2">
    <b-skeleton type="button" v-if="status"></b-skeleton>
    <h5 v-else class="text-color-444 weight-bold fontsize-large" :class="mode == 'flash' ? 'text-wrap' : 'text-nowrap'">{{ text }}</h5>
    <div :class="mode == 'flash' ? 'campaigns-line' : 'line bg-color-c8 w-75'"></div>
    <b-skeleton v-if="status" type="button"></b-skeleton>

    <router-link
      v-else
      :to="route"
      class="
          campaigns-more-btn
          py-2
          px-3
          text-color-444
          fontsize-small
          text-nowrap
        "
    >
      {{ textBtn }}
    </router-link>
  </div>
</template>
<script>
import {BSkeletonTable, BSkeleton} from 'bootstrap-vue'

export default {
  components: {
    BSkeletonTable, BSkeleton
  },
  props: {
    status: { Boolean },
    text: { String },
    route: { String },
    textBtn: { String },
    mode: String,
  },
};
</script>
