<template>
  <section id="allFlashProducts" class="product-campaigns">
    <section v-for="(flash, index) in flashes" :key="flash.id" class="container" :class="index != 0 ? 'py-5' : ''">
      <HeaderSection :mode="'flash'" :textBtn="'محصولات بیشتر'" :status="false" :text="flash.title" :route="getUrl(flash)" />
        <section class="container-fluid gradient-box">
          <div class="row">
            <div class="col-lg-2 d-none d-lg-flex justify-content-center">
              <div class="banner-card">
                <img :src="flash.image.url" :alt="flash.title">
                  <router-link :to="getUrl(flash)" class="text-light weight-bold py-2 px-3 fontsize-medium text-nowrap"><ChevronSvg/> مشاهده محصولات</router-link>
              </div>
            </div>
            <div class="col-12 col-lg-10 rowCard">
              <div class="swiper-container" :id="'flash-swiper' + flash.id">
                  <div class="swiper-wrapper" v-if="flash.products != '' && !disabledSliders.includes('myProducts')">
                    <div class="swiper-slide d-flex d-lg-none">
                        <div class="banner-card">
                          <img :src="flash.image.url" :alt="flash.title">
                          <router-link :to="getUrl(flash)" class="text-light weight-bold py-2 px-3 fontsize-medium text-nowrap"><ChevronSvg/> مشاهده محصولات</router-link>
                        </div>
                      </div>
                      <FlashProduct v-for="product in flash.products" :key="product.id" :product="product"/>
                  </div>
              </div>
            </div>
          </div>
        </section>
    </section>
  </section>
</template>
<script>

import Swiper from 'swiper/swiper-bundle.min'
import HeaderSection from '@/parts/Front/components/HeaderSection'
import FlashProduct from '@/parts/Front/components/flash/FlashProduct'
import ChevronSvg from '@/parts/Front/components/svg/ChevronSvg.vue'

export default {
    name: "Flashes",
    props: ['flash', 'flashes'],
    components: {
      FlashProduct,HeaderSection,ChevronSvg
    },
    data() {
      return {
        disabledSliders: []
      }
    },
    watch: {
    },
    mounted() {
      this.initFlashProducts()
    },
    methods: {
      getUrl(flash) {
        return '/products?flash_id=' + flash.id
      },
      initFlashProducts(kill = false) {
        this.initSwiper('myProducts', kill, () => {
          new Swiper('.product-campaigns .swiper-container', {
            spaceBetween: 1,
            loop: false,
            speed: 500,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            navigation: {
              nextEl: '.product-campaigns .swiper-button-next',
              prevEl: '.product-campaigns .swiper-button-prev',
            },
            breakpoints: {
              300: {
                slidesPerView: 1.4,
                spaceBetween: 10,
              },
              500: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
              slidesPerView: 4,
              spaceBetween: 10,
              }
            }
          });
        })
      },
    }
  }
</script>

<style >
#allFlashProducts .gradient-box {
  background-color: var(--color-theme);
  border-radius: 15px;
  padding: 40px;
}
#allFlashProducts .banner-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
#allFlashProducts .banner-card img {
  width: 100%;
  object-fit: cover;
}

.rowCard{
  display: flex;
  align-items: center;
}
@media (max-width:767px) {
  #allFlashProducts .gradient-box {
  padding: 15px;
}
#allFlashProducts .banner-card img {
  width: 80%;
  object-fit: cover;
}
}
</style>
